.image {
    width: 100%;
    height: 100px;
    transition-duration: 1s;
    /* border-radius: 50%; */
    border: 1px solid red;
}

.image:hover {
    cursor: pointer;
    box-shadow: inset;
    box-shadow: 0px 0px 10px 5px rgb(193, 192, 192);
}

.imageBtn {
    width: 70%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(16, 138, 0, 1);
    transition-duration: 1s;
}

.imageBtn:hover {
    background-color: rgba(16, 138, 0, 10);
    box-shadow: 0px 0px 10px 5px rgb(193, 192, 192);
}