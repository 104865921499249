.imageDiv {
    /* border: 1px solid green; */
    width: 50%;
    height: auto;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profileImage {
    width: 100%;
    height: 45px;
    background-color: #108A00;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    color: white;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.profileImage:hover {
    box-shadow: inset;
    background-color: green;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}